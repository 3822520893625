import request from '@/utils/request'

//获取个人实名凭证：24小时内最多实名6次
export function getPersonAuthNo(data) {
  return request({
    url: '/rpc/tools/pageIdCardVerify',
    method: 'post',
    data
  })
}

//个人实名校验
export function personalVerify(data) {
  return request({
    url: '/authz/realNameVerify/personal/pageVerify',
    method: 'post',
    data
  })
}

//获取企业实名凭证：24小时内最多实名6次
export function getEnterpriseAuthNo(data) {
  return request({
    url: '/rpc/tools/pageEnterpriseVerify',
    method: 'post',
    data
  })
}

//企业实名校验
export function enterpriseVerify(data) {
  return request({
    url: '/authz/realNameVerify/enterprise/pageVerify',
    method: 'post',
    data
  })
}
