<template>
  <el-upload
    class="avatar-uploader"
    action="/api/file/fileService/uploadFile"
    :show-file-list="false"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <img v-if="src" :src="src" class="avatar" />
    <span v-else class="avatar-uploader-content">
      <i class="el-icon-plus avatar-uploader-icon" />
      {{ title }}
    </span>
  </el-upload>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },

  computed: {},

  watch: {},

  mounted() {},

  methods: {}
}
</script>
<style lang="scss" scoped>
.avatar-uploader {
  display: inline-block;
}

.avatar-uploader /deep/.el-upload {
  position: relative;
  margin-right: 30px;
  border: 1px dashed #d4d4d4;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
}

.avatar-uploader /deep/.el-upload:hover {
  border-color: #409eff;

  .avatar-uploader-content {
    color: #409eff;
  }
}

.avatar-uploader-content {
  display: inline-flex;
  width: 178px;
  height: 178px;
  text-align: center;
  color: #979797;
  flex-direction: column;
  justify-content: center;
}

.avatar-uploader-icon {
  display: block;
  font-size: 28px;
}

.avatar {
  display: block;
  width: 178px;
  height: 178px;
}
</style>
