<template>
  <div>
    <el-form ref="form" class="company-form" :model="form" :rules="rules" label-width="130px">
      <el-divider>企业实名</el-divider>
      <el-form-item label="企业名称" prop="companyName">
        <el-input v-model="form.companyName" placeholder="请输入企业名称" />
      </el-form-item>
      <el-form-item label="证件类型" prop="idType">
        <el-select v-model="form.idType">
          <el-option v-for="item of typeOpts" :key="item.id" :value="item.id" :label="item.label" />
        </el-select>
      </el-form-item>
      <el-form-item label="证件号码" prop="companyNo">
        <el-input v-model="form.companyNo" placeholder="请输入信用代码，限18-20位数字、大写英文" />
      </el-form-item>
      <el-form-item label="营业执照照片" prop="licenseUrl" class="photo-form-item">
        <img-popover type="company" />
        <upload
          action="/api/file/fileService/uploadFile"
          title="上传营业执照照片"
          :src="licenseUrl"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        />
      </el-form-item>

      <el-divider>企业法人实名</el-divider>
      <el-form-item label="企业代表人姓名" prop="username">
        <el-input v-model="form.username" />
      </el-form-item>
      <el-form-item label="居民身份证号" prop="idNo">
        <el-input v-model="form.idNo" />
      </el-form-item>
      <el-form-item label="身份证照片" prop="cardUrl" class="photo-form-item">
        <img-popover />
        <upload
          action="/api/file/fileService/uploadFile"
          title="上传人像页照片"
          :src="cardUrl1"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        />
        <upload
          action="/api/file/fileService/uploadFile"
          title="上传国徽页照片"
          :src="cardUrl2"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        />
      </el-form-item>
    </el-form>

    <el-button type="primary" class="col" @click="save">立即提交</el-button>
  </div>
</template>

<script>
import imgPopover from './template/ImgPopover'
import upload from './template/Upload'

export default {
  components: { imgPopover, upload },
  data() {
    const validCompanyNo = (rule, value, callback) => {
      let reg = /^([0-9A-Z]{18,20})$/
      if (!value) {
        callback(new Error('请输入信用代码，限18-20位数字、大写英文'))
      } else if (!reg.test(value)) {
        callback(new Error('请输入信用代码，限18-20位数字、大写英文'))
      }
      callback()
    }
    // const validLicenseUrl = (rule, value, callback) => {
    //   if (!this.licenseUrl) {
    //     callback(new Error('请上传营业执照照片'))
    //   }
    //   callback()
    // }
    // const validCardUrl = (rule, value, callback) => {
    //   if (!this.cardUrl1 && !this.cardUrl2) {
    //     callback(new Error('请上传身份证照片'))
    //   } else if (!this.cardUrl1) {
    //     callback(new Error('请上传人像页照片'))
    //   } else if (!this.cardUrl2) {
    //     callback(new Error('请上传国徽页照片'))
    //   }
    //   callback()
    // }
    return {
      form: {
        companyName: '',
        idType: 126,
        companyNo: '',
        username: '',
        idNo: ''
      },
      rules: {
        companyName: { required: true, trigger: 'blur', message: '请输入企业名称' },
        idType: { required: true, trigger: 'blur', message: '请输入企业证件类型' },
        companyNo: {
          required: true,
          trigger: 'blur',
          message: '请输入信用代码，限18-20位数字、大写英文',
          validator: validCompanyNo
        },
        username: { required: true, trigger: 'blur', message: '法定代表人姓名' },
        idNo: { required: true, trigger: 'blur', validator: this.$utils.validIdNo }
        // licenseUrl: { required: true, trigger: 'blur', validator: validLicenseUrl },
        // cardUrl: { required: true, trigger: 'blur', validator: validCardUrl }
      },
      typeOpts: [
        {
          id: 126,
          label: '社会统一信用代码证'
        },
        {
          id: 120,
          label: '其他'
        }
      ],
      licenseUrl: '',
      cardUrl1: '',
      cardUrl2: ''
    }
  },

  computed: {},
  watch: {},
  mounted() {},

  methods: {
    beforeAvatarUpload() {},
    handleAvatarSuccess() {},
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('save', this.form)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.company-form {
  width: 600px;
  padding: 10px 0;
  margin: auto;
}

.col {
  width: 440px;
}
</style>
