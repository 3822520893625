<template>
  <el-popover placement="bottom-start" width="600" trigger="hover" class="popover">
    <div>
      <div class="id-card-wrap">{{ title }}</div>
      <div v-if="type === 'idCard'">
        <img class="id-card-img" src="@/assets/images/idcard1.png" alt="" />
        <img class="id-card-img" src="@/assets/images/idcard2.png" alt="" />
        <span class="id-card-txt">(人像页)</span>
        <span class="id-card-txt">(国徽页)</span>
      </div>
      <img v-else-if="type === 'company'" class="company-img id-card-img" src="@/assets/images/license.png" alt="" />
      <div class="tips">
        <div class="tips-title">图片要求：</div>
        <ul>
          <li v-for="(item, index) of tips" :key="index">{{ `${index + 1}.${item}` }}</li>
        </ul>
      </div>
    </div>
    <el-button slot="reference" type="text">示例图</el-button>
  </el-popover>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'idCard'
    }
  },
  data() {
    return {
      mesData: {
        company: {
          title: '营业执照示例图',
          tips: [
            '证件上的信息清晰可见。',
            '证件照片内容真实有效，不可做任何修改。',
            '单张图片大小不得超过10M，允许上传的图片类型：jpg、png。',
            '建议拍摄角度保持垂直，避免倾斜过大造成图片变形。',
            '请提交营业执照原件的正面彩色图片，不要提交黑白图片或复印件。',
            '建议营业执照图片保持完整，避免裁剪边角框、有污迹、划痕或折痕。',
            '营业执照图片中文字、证件号码尽量清晰可辨，建议不要涂改或在名字和证件号文字上添加水印。'
          ]
        },
        idCard: {
          title: '身份证示例图',
          tips: [
            '请上传身份证原件正反面图片，单张图片大小不得超过8M，允许上传的图片 类型：jpg、gif、png。',
            '建议拍摄角度保持垂真，避免倾斜过大 造成图像变形。',
            '请提交第二代居民身份证原件的正面彩色图片，不要提交黑白图片或复印件。',
            '建议身份证图片保持完整，避免裁剪边角框、有污迹、划痕或折痕。',
            '身份证图片中文字、证件号码尽量清晰可辨，建议不要涂改或在名字和证件号文字上添加水印。'
          ]
        }
      }
    }
  },

  computed: {
    tips() {
      return this.mesData[this.type]?.tips
    },
    title() {
      return this.mesData[this.type]?.title
    }
  },

  watch: {},

  mounted() {},

  methods: {}
}
</script>
<style lang="scss" scoped>
.id-card-wrap {
  height: 45px;
  line-height: 45px;
}

.id-card-img {
  border: 1px dashed;
  width: 260px;
  margin: 0 5px;
}

.company-img {
  width: 190px;
}

.id-card-txt {
  display: inline-block;
  width: 260px;
  margin: 5px;
  text-align: center;
}

.tips {
  font-size: 12px;
  margin-bottom: 5%;
  margin-top: 20px;
  padding-left: 10px;
  color: #777;

  .tips-title {
    color: #0c0c0c;
    margin-bottom: 10px;
  }

  li {
    font-size: 12px;
    padding: 2px;
  }
}
</style>
